import React from 'react'
import { Link } from 'gatsby'
import '../styles/subnavbar.sass'

const SubNavbar = () => {
  return (
    <nav
      className="subnavbar-hero"
      role="navigation"
      aria-label="main-navigation"
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Link className="subnav-item" to="/about">
          About
        </Link>
        <div className="subseparator">•</div>
        <Link className="subnav-item" to="/books">
          Books
        </Link>
        <div className="subseparator">•</div>
        <Link className="subnav-item" to="/press">
          Press
        </Link>
        <div className="subseparator">•</div>
        <Link className="subnav-item" to="/contact">
          Contact
        </Link>
      </div>
    </nav>
  )
}

export default SubNavbar
