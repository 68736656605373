import React from 'react'
import Navbar from '../../components/Navbar'
import '../../styles/contactform.sass'
import { Link } from 'gatsby'

export default () => (
  <div className="contact-container">
    <div className="">
      <Navbar showSub={false} showMenu={true} bold />
      <div className="contact-header">
        <h1>Thank you! We'll be in contact with you soon.</h1>
        <h1>
          <Link to="/">Back to home</Link>
        </h1>
      </div>
    </div>
  </div>
)
